/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number
// @generated from protobuf file "relay/relay.proto" (package "relay", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Cilium
//
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
/**
 * NodeStatusEvent is a message sent by hubble-relay to inform clients about
 * the state of a particular node.
 *
 * @generated from protobuf message relay.NodeStatusEvent
 */
export interface NodeStatusEvent {
    /**
     * state_change contains the new node state
     *
     * @generated from protobuf field: relay.NodeState state_change = 1;
     */
    stateChange: NodeState;
    /**
     * node_names is the list of nodes for which the above state changes applies
     *
     * @generated from protobuf field: repeated string node_names = 2;
     */
    nodeNames: string[];
    /**
     * message is an optional message attached to the state change (e.g. an
     * error message). The message applies to all nodes in node_names.
     *
     * @generated from protobuf field: string message = 3;
     */
    message: string;
}
/**
 * @generated from protobuf enum relay.NodeState
 */
export enum NodeState {
    /**
     * UNKNOWN_NODE_STATE indicates that the state of this node is unknown.
     *
     * @generated from protobuf enum value: UNKNOWN_NODE_STATE = 0;
     */
    UNKNOWN_NODE_STATE = 0,
    /**
     * NODE_CONNECTED indicates that we have established a connection
     * to this node. The client can expect to observe flows from this node.
     *
     * @generated from protobuf enum value: NODE_CONNECTED = 1;
     */
    NODE_CONNECTED = 1,
    /**
     * NODE_UNAVAILABLE indicates that the connection to this
     * node is currently unavailable. The client can expect to not see any
     * flows from this node until either the connection is re-established or
     * the node is gone.
     *
     * @generated from protobuf enum value: NODE_UNAVAILABLE = 2;
     */
    NODE_UNAVAILABLE = 2,
    /**
     * NODE_GONE indicates that a node has been removed from the
     * cluster. No reconnection attempts will be made.
     *
     * @generated from protobuf enum value: NODE_GONE = 3;
     */
    NODE_GONE = 3,
    /**
     * NODE_ERROR indicates that a node has reported an error while processing
     * the request. No reconnection attempts will be made.
     *
     * @generated from protobuf enum value: NODE_ERROR = 4;
     */
    NODE_ERROR = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class NodeStatusEvent$Type extends MessageType<NodeStatusEvent> {
    constructor() {
        super("relay.NodeStatusEvent", [
            { no: 1, name: "state_change", kind: "enum", T: () => ["relay.NodeState", NodeState] },
            { no: 2, name: "node_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "message", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<NodeStatusEvent>): NodeStatusEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.stateChange = 0;
        message.nodeNames = [];
        message.message = "";
        if (value !== undefined)
            reflectionMergePartial<NodeStatusEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NodeStatusEvent): NodeStatusEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* relay.NodeState state_change */ 1:
                    message.stateChange = reader.int32();
                    break;
                case /* repeated string node_names */ 2:
                    message.nodeNames.push(reader.string());
                    break;
                case /* string message */ 3:
                    message.message = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NodeStatusEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* relay.NodeState state_change = 1; */
        if (message.stateChange !== 0)
            writer.tag(1, WireType.Varint).int32(message.stateChange);
        /* repeated string node_names = 2; */
        for (let i = 0; i < message.nodeNames.length; i++)
            writer.tag(2, WireType.LengthDelimited).string(message.nodeNames[i]);
        /* string message = 3; */
        if (message.message !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.message);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message relay.NodeStatusEvent
 */
export const NodeStatusEvent = new NodeStatusEvent$Type();
