/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number
// @generated from protobuf file "ui/ui.proto" (package "ui", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// Copyright 2020 Authors of Cilium
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
import { GetStatusResponse } from "./status_pb";
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import { WireType } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { Duration } from "../google/protobuf/duration_pb";
import { AuthType } from "../flow/flow_pb";
import { Verdict } from "../flow/flow_pb";
import { Workload } from "../flow/flow_pb";
import { FlowFilter } from "../flow/flow_pb";
import { Notification } from "./notifications_pb";
import { Flow } from "../flow/flow_pb";
import { GetStatusRequest } from "./status_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
/**
 * Here I didn't include "follow", "until", and "number". This request assumes follow,
 * and lets the client decide when to end the request, whether it's based on timestamp
 * or the number of responses received.
 *
 * @generated from protobuf message ui.GetEventsRequest
 */
export interface GetEventsRequest {
    /**
     * EventType specifies which types of events to subscribe to. If unspecified, the
     * request is subscribed to all the event types.
     *
     * @generated from protobuf field: repeated ui.EventType event_types = 1;
     */
    eventTypes: EventType[];
    /**
     * @generated from protobuf field: repeated ui.EventFilter blacklist = 2;
     */
    blacklist: EventFilter[];
    /**
     * @generated from protobuf field: repeated ui.EventFilter whitelist = 3;
     */
    whitelist: EventFilter[];
    /**
     * @generated from protobuf field: google.protobuf.Timestamp since = 4;
     */
    since?: Timestamp;
    /**
     * @generated from protobuf field: ui.GetStatusRequest status_request = 5;
     */
    statusRequest?: GetStatusRequest;
}
/**
 * @generated from protobuf message ui.GetEventsResponse
 */
export interface GetEventsResponse {
    /**
     * @generated from protobuf field: string node = 1;
     */
    node: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp timestamp = 2;
     */
    timestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated ui.Event events = 3;
     */
    events: Event[];
}
/**
 * @generated from protobuf message ui.Event
 */
export interface Event {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 3;
         */
        flow: Flow;
    } | {
        oneofKind: "namespaceState";
        /**
         * @generated from protobuf field: ui.NamespaceState namespace_state = 4;
         */
        namespaceState: NamespaceState;
    } | {
        oneofKind: "serviceState";
        /**
         * @generated from protobuf field: ui.ServiceState service_state = 5;
         */
        serviceState: ServiceState;
    } | {
        oneofKind: "serviceLinkState";
        /**
         * It's unclear how we can define what it means for service links to get
         * added/deleted. For now I'm deferring the decision for whether a
         * particular link should be included in the service map to the client.
         * Maybe the server can periodically send service_link events for active
         * links to keep-alive, and the client can expire older links based on their
         * timestamp.
         *
         * @generated from protobuf field: ui.ServiceLinkState service_link_state = 6;
         */
        serviceLinkState: ServiceLinkState;
    } | {
        oneofKind: "flows";
        /**
         * @generated from protobuf field: ui.Flows flows = 7;
         */
        flows: Flows;
    } | {
        oneofKind: "notification";
        /**
         * @generated from protobuf field: ui.Notification notification = 8;
         */
        notification: Notification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.Flows
 */
export interface Flows {
    /**
     * @generated from protobuf field: repeated flow.Flow flows = 1;
     */
    flows: Flow[];
}
/**
 * Here I added different filter types for different events. Another option is to reuse
 * and extend FlowFilter to filter all the events, but I worry that it might become too
 * confusing in terms of figuring out which filter fields apply to which events.
 *
 * @generated from protobuf message ui.EventFilter
 */
export interface EventFilter {
    /**
     * @generated from protobuf oneof: filter
     */
    filter: {
        oneofKind: "flowFilter";
        /**
         * @generated from protobuf field: flow.FlowFilter flow_filter = 2;
         */
        flowFilter: FlowFilter;
    } | {
        oneofKind: "serviceFilter";
        /**
         * @generated from protobuf field: ui.ServiceFilter service_filter = 3;
         */
        serviceFilter: ServiceFilter;
    } | {
        oneofKind: "serviceLinkFilter";
        /**
         * @generated from protobuf field: ui.ServiceLinkFilter service_link_filter = 4;
         */
        serviceLinkFilter: ServiceLinkFilter;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.NamespaceDescriptor
 */
export interface NamespaceDescriptor {
    /**
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 3;
     */
    creationTimestamp?: Timestamp;
}
/**
 * @generated from protobuf message ui.NamespaceState
 */
export interface NamespaceState {
    /**
     * @generated from protobuf field: ui.NamespaceDescriptor namespace = 1;
     */
    namespace?: NamespaceDescriptor;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * Come up with a better name for Service.
 *
 * @generated from protobuf message ui.Service
 */
export interface Service {
    /**
     * An opaque ID that uniquely identifies the service.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * @generated from protobuf field: string name = 2;
     */
    name: string;
    /**
     * @generated from protobuf field: string namespace = 3;
     */
    namespace: string;
    /**
     * @generated from protobuf field: repeated string labels = 4;
     */
    labels: string[];
    /**
     * @generated from protobuf field: repeated string dns_names = 5;
     */
    dnsNames: string[];
    /**
     * @generated from protobuf field: bool egress_policy_enforced = 6;
     */
    egressPolicyEnforced: boolean;
    /**
     * @generated from protobuf field: bool ingress_policy_enforced = 7;
     */
    ingressPolicyEnforced: boolean;
    /**
     * This is a string instead of boolean because Cilium reports status message. If it's
     * successfully enabled, this string is set to "OK" I think.
     *
     * @generated from protobuf field: string visibility_policy_status = 8;
     */
    visibilityPolicyStatus: string;
    /**
     * We probably can't reliably set creation timestamp.
     *
     * @generated from protobuf field: google.protobuf.Timestamp creation_timestamp = 9;
     */
    creationTimestamp?: Timestamp;
    /**
     * @generated from protobuf field: repeated flow.Workload workloads = 10;
     */
    workloads: Workload[];
    /**
     * @generated from protobuf field: uint32 identity = 12;
     */
    identity: number;
}
/**
 * @generated from protobuf message ui.ServiceState
 */
export interface ServiceState {
    /**
     * @generated from protobuf field: ui.Service service = 1;
     */
    service?: Service;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * @generated from protobuf message ui.ServiceFilter
 */
export interface ServiceFilter {
    /**
     * @generated from protobuf field: repeated string namespace = 1;
     */
    namespace: string[];
}
/**
 * @generated from protobuf message ui.ServiceLink
 */
export interface ServiceLink {
    /**
     * An opaque ID that uniquely identifies this link.
     *
     * @generated from protobuf field: string id = 1;
     */
    id: string;
    /**
     * source Service id
     *
     * @generated from protobuf field: string source_id = 2;
     */
    sourceId: string;
    /**
     * destination Service id
     *
     * @generated from protobuf field: string destination_id = 3;
     */
    destinationId: string;
    /**
     * @generated from protobuf field: uint32 destination_port = 4;
     */
    destinationPort: number;
    /**
     * @generated from protobuf field: ui.IPProtocol ip_protocol = 5;
     */
    ipProtocol: IPProtocol;
    /**
     * @generated from protobuf field: flow.Verdict verdict = 6;
     */
    verdict: Verdict;
    /**
     * @generated from protobuf field: uint64 flow_amount = 7;
     */
    flowAmount: number;
    /**
     * @generated from protobuf field: ui.ServiceLink.Latency latency = 8;
     */
    latency?: ServiceLink_Latency;
    /**
     * @generated from protobuf field: uint64 bytes_transfered = 9;
     */
    bytesTransfered: number;
    /**
     * @generated from protobuf field: flow.AuthType auth_type = 10;
     */
    authType: AuthType;
    /**
     * @generated from protobuf field: bool is_encrypted = 11;
     */
    isEncrypted: boolean;
}
/**
 * @generated from protobuf message ui.ServiceLink.Latency
 */
export interface ServiceLink_Latency {
    /**
     * @generated from protobuf field: google.protobuf.Duration min = 1;
     */
    min?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration max = 2;
     */
    max?: Duration;
    /**
     * @generated from protobuf field: google.protobuf.Duration avg = 3;
     */
    avg?: Duration;
}
/**
 * @generated from protobuf message ui.ServiceLinkState
 */
export interface ServiceLinkState {
    /**
     * @generated from protobuf field: ui.ServiceLink service_link = 1;
     */
    serviceLink?: ServiceLink;
    /**
     * @generated from protobuf field: ui.StateChange type = 2;
     */
    type: StateChange;
}
/**
 * @generated from protobuf message ui.ServiceLinkFilter
 */
export interface ServiceLinkFilter {
    /**
     * @generated from protobuf field: repeated ui.ServiceFilter source = 1;
     */
    source: ServiceFilter[];
    /**
     * @generated from protobuf field: repeated ui.ServiceFilter destination = 2;
     */
    destination: ServiceFilter[];
    /**
     * Use string here to support things like port ranges (e.g. 100-200).
     *
     * @generated from protobuf field: repeated string destination_port = 3;
     */
    destinationPort: string[];
    /**
     * @generated from protobuf field: repeated flow.Verdict verdict = 4;
     */
    verdict: Verdict[];
}
/**
 * @generated from protobuf message ui.GetControlStreamRequest
 */
export interface GetControlStreamRequest {
}
/**
 * @generated from protobuf message ui.GetControlStreamResponse
 */
export interface GetControlStreamResponse {
    /**
     * @generated from protobuf oneof: event
     */
    event: {
        oneofKind: "namespaces";
        /**
         * @generated from protobuf field: ui.GetControlStreamResponse.NamespaceStates namespaces = 1;
         */
        namespaces: GetControlStreamResponse_NamespaceStates;
    } | {
        oneofKind: "notification";
        /**
         * @generated from protobuf field: ui.Notification notification = 2;
         */
        notification: Notification;
    } | {
        oneofKind: undefined;
    };
}
/**
 * @generated from protobuf message ui.GetControlStreamResponse.NamespaceStates
 */
export interface GetControlStreamResponse_NamespaceStates {
    /**
     * @generated from protobuf field: repeated ui.NamespaceState namespaces = 1;
     */
    namespaces: NamespaceState[];
}
/**
 * @generated from protobuf enum ui.EventType
 */
export enum EventType {
    /**
     * @generated from protobuf enum value: UNKNOWN_EVENT = 0;
     */
    UNKNOWN_EVENT = 0,
    /**
     * @generated from protobuf enum value: FLOW = 1;
     */
    FLOW = 1,
    /**
     * @generated from protobuf enum value: K8S_NAMESPACE_STATE = 2;
     */
    K8S_NAMESPACE_STATE = 2,
    /**
     * @generated from protobuf enum value: SERVICE_STATE = 3;
     */
    SERVICE_STATE = 3,
    /**
     * @generated from protobuf enum value: SERVICE_LINK_STATE = 4;
     */
    SERVICE_LINK_STATE = 4,
    /**
     * @generated from protobuf enum value: FLOWS = 5;
     */
    FLOWS = 5,
    /**
     * @generated from protobuf enum value: STATUS = 6;
     */
    STATUS = 6
}
/**
 * IP protocols. The values of enums do not correspond to actual IP protocol numbers.
 *
 * @generated from protobuf enum ui.IPProtocol
 */
export enum IPProtocol {
    /**
     * @generated from protobuf enum value: UNKNOWN_IP_PROTOCOL = 0;
     */
    UNKNOWN_IP_PROTOCOL = 0,
    /**
     * @generated from protobuf enum value: TCP = 1;
     */
    TCP = 1,
    /**
     * @generated from protobuf enum value: UDP = 2;
     */
    UDP = 2,
    /**
     * @generated from protobuf enum value: ICMP_V4 = 3;
     */
    ICMP_V4 = 3,
    /**
     * @generated from protobuf enum value: ICMP_V6 = 4;
     */
    ICMP_V6 = 4
}
/**
 * @generated from protobuf enum ui.StateChange
 */
export enum StateChange {
    /**
     * @generated from protobuf enum value: UNKNOWN_STATE_CHANGE = 0;
     */
    UNKNOWN_STATE_CHANGE = 0,
    /**
     * @generated from protobuf enum value: ADDED = 1;
     */
    ADDED = 1,
    /**
     * @generated from protobuf enum value: MODIFIED = 2;
     */
    MODIFIED = 2,
    /**
     * @generated from protobuf enum value: DELETED = 3;
     */
    DELETED = 3,
    /**
     * This is needed in case the relay server knows that the service exists,
     * but it doesn't know when it got created.
     *
     * @generated from protobuf enum value: EXISTS = 4;
     */
    EXISTS = 4
}
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsRequest$Type extends MessageType<GetEventsRequest> {
    constructor() {
        super("ui.GetEventsRequest", [
            { no: 1, name: "event_types", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["ui.EventType", EventType] },
            { no: 2, name: "blacklist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 3, name: "whitelist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => EventFilter },
            { no: 4, name: "since", kind: "message", T: () => Timestamp },
            { no: 5, name: "status_request", kind: "message", T: () => GetStatusRequest }
        ]);
    }
    create(value?: PartialMessage<GetEventsRequest>): GetEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.eventTypes = [];
        message.blacklist = [];
        message.whitelist = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsRequest): GetEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.EventType event_types */ 1:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.eventTypes.push(reader.int32());
                    else
                        message.eventTypes.push(reader.int32());
                    break;
                case /* repeated ui.EventFilter blacklist */ 2:
                    message.blacklist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.EventFilter whitelist */ 3:
                    message.whitelist.push(EventFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp since */ 4:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* ui.GetStatusRequest status_request */ 5:
                    message.statusRequest = GetStatusRequest.internalBinaryRead(reader, reader.uint32(), options, message.statusRequest);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.EventType event_types = 1; */
        if (message.eventTypes.length) {
            writer.tag(1, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.eventTypes.length; i++)
                writer.int32(message.eventTypes[i]);
            writer.join();
        }
        /* repeated ui.EventFilter blacklist = 2; */
        for (let i = 0; i < message.blacklist.length; i++)
            EventFilter.internalBinaryWrite(message.blacklist[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.EventFilter whitelist = 3; */
        for (let i = 0; i < message.whitelist.length; i++)
            EventFilter.internalBinaryWrite(message.whitelist[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp since = 4; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.GetStatusRequest status_request = 5; */
        if (message.statusRequest)
            GetStatusRequest.internalBinaryWrite(message.statusRequest, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetEventsRequest
 */
export const GetEventsRequest = new GetEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetEventsResponse$Type extends MessageType<GetEventsResponse> {
    constructor() {
        super("ui.GetEventsResponse", [
            { no: 1, name: "node", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "timestamp", kind: "message", T: () => Timestamp },
            { no: 3, name: "events", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Event }
        ]);
    }
    create(value?: PartialMessage<GetEventsResponse>): GetEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.node = "";
        message.events = [];
        if (value !== undefined)
            reflectionMergePartial<GetEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetEventsResponse): GetEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string node */ 1:
                    message.node = reader.string();
                    break;
                case /* google.protobuf.Timestamp timestamp */ 2:
                    message.timestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.timestamp);
                    break;
                case /* repeated ui.Event events */ 3:
                    message.events.push(Event.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string node = 1; */
        if (message.node !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.node);
        /* google.protobuf.Timestamp timestamp = 2; */
        if (message.timestamp)
            Timestamp.internalBinaryWrite(message.timestamp, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.Event events = 3; */
        for (let i = 0; i < message.events.length; i++)
            Event.internalBinaryWrite(message.events[i], writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetEventsResponse
 */
export const GetEventsResponse = new GetEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Event$Type extends MessageType<Event> {
    constructor() {
        super("ui.Event", [
            { no: 3, name: "flow", kind: "message", oneof: "event", T: () => Flow },
            { no: 4, name: "namespace_state", kind: "message", oneof: "event", T: () => NamespaceState },
            { no: 5, name: "service_state", kind: "message", oneof: "event", T: () => ServiceState },
            { no: 6, name: "service_link_state", kind: "message", oneof: "event", T: () => ServiceLinkState },
            { no: 7, name: "flows", kind: "message", oneof: "event", T: () => Flows },
            { no: 8, name: "notification", kind: "message", oneof: "event", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<Event>): Event {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<Event>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Event): Event {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 3:
                    message.event = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).flow)
                    };
                    break;
                case /* ui.NamespaceState namespace_state */ 4:
                    message.event = {
                        oneofKind: "namespaceState",
                        namespaceState: NamespaceState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).namespaceState)
                    };
                    break;
                case /* ui.ServiceState service_state */ 5:
                    message.event = {
                        oneofKind: "serviceState",
                        serviceState: ServiceState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).serviceState)
                    };
                    break;
                case /* ui.ServiceLinkState service_link_state */ 6:
                    message.event = {
                        oneofKind: "serviceLinkState",
                        serviceLinkState: ServiceLinkState.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).serviceLinkState)
                    };
                    break;
                case /* ui.Flows flows */ 7:
                    message.event = {
                        oneofKind: "flows",
                        flows: Flows.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).flows)
                    };
                    break;
                case /* ui.Notification notification */ 8:
                    message.event = {
                        oneofKind: "notification",
                        notification: Notification.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).notification)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Event, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 3; */
        if (message.event.oneofKind === "flow")
            Flow.internalBinaryWrite(message.event.flow, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.NamespaceState namespace_state = 4; */
        if (message.event.oneofKind === "namespaceState")
            NamespaceState.internalBinaryWrite(message.event.namespaceState, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceState service_state = 5; */
        if (message.event.oneofKind === "serviceState")
            ServiceState.internalBinaryWrite(message.event.serviceState, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceLinkState service_link_state = 6; */
        if (message.event.oneofKind === "serviceLinkState")
            ServiceLinkState.internalBinaryWrite(message.event.serviceLinkState, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* ui.Flows flows = 7; */
        if (message.event.oneofKind === "flows")
            Flows.internalBinaryWrite(message.event.flows, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* ui.Notification notification = 8; */
        if (message.event.oneofKind === "notification")
            Notification.internalBinaryWrite(message.event.notification, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Event
 */
export const Event = new Event$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Flows$Type extends MessageType<Flows> {
    constructor() {
        super("ui.Flows", [
            { no: 1, name: "flows", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Flow }
        ]);
    }
    create(value?: PartialMessage<Flows>): Flows {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.flows = [];
        if (value !== undefined)
            reflectionMergePartial<Flows>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Flows): Flows {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated flow.Flow flows */ 1:
                    message.flows.push(Flow.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Flows, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated flow.Flow flows = 1; */
        for (let i = 0; i < message.flows.length; i++)
            Flow.internalBinaryWrite(message.flows[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Flows
 */
export const Flows = new Flows$Type();
// @generated message type with reflection information, may provide speed optimized methods
class EventFilter$Type extends MessageType<EventFilter> {
    constructor() {
        super("ui.EventFilter", [
            { no: 2, name: "flow_filter", kind: "message", oneof: "filter", T: () => FlowFilter },
            { no: 3, name: "service_filter", kind: "message", oneof: "filter", T: () => ServiceFilter },
            { no: 4, name: "service_link_filter", kind: "message", oneof: "filter", T: () => ServiceLinkFilter }
        ]);
    }
    create(value?: PartialMessage<EventFilter>): EventFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.filter = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<EventFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: EventFilter): EventFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.FlowFilter flow_filter */ 2:
                    message.filter = {
                        oneofKind: "flowFilter",
                        flowFilter: FlowFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).flowFilter)
                    };
                    break;
                case /* ui.ServiceFilter service_filter */ 3:
                    message.filter = {
                        oneofKind: "serviceFilter",
                        serviceFilter: ServiceFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).serviceFilter)
                    };
                    break;
                case /* ui.ServiceLinkFilter service_link_filter */ 4:
                    message.filter = {
                        oneofKind: "serviceLinkFilter",
                        serviceLinkFilter: ServiceLinkFilter.internalBinaryRead(reader, reader.uint32(), options, (message.filter as any).serviceLinkFilter)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: EventFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.FlowFilter flow_filter = 2; */
        if (message.filter.oneofKind === "flowFilter")
            FlowFilter.internalBinaryWrite(message.filter.flowFilter, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceFilter service_filter = 3; */
        if (message.filter.oneofKind === "serviceFilter")
            ServiceFilter.internalBinaryWrite(message.filter.serviceFilter, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* ui.ServiceLinkFilter service_link_filter = 4; */
        if (message.filter.oneofKind === "serviceLinkFilter")
            ServiceLinkFilter.internalBinaryWrite(message.filter.serviceLinkFilter, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.EventFilter
 */
export const EventFilter = new EventFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamespaceDescriptor$Type extends MessageType<NamespaceDescriptor> {
    constructor() {
        super("ui.NamespaceDescriptor", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "creation_timestamp", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<NamespaceDescriptor>): NamespaceDescriptor {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        if (value !== undefined)
            reflectionMergePartial<NamespaceDescriptor>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamespaceDescriptor): NamespaceDescriptor {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* google.protobuf.Timestamp creation_timestamp */ 3:
                    message.creationTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.creationTimestamp);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NamespaceDescriptor, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* google.protobuf.Timestamp creation_timestamp = 3; */
        if (message.creationTimestamp)
            Timestamp.internalBinaryWrite(message.creationTimestamp, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.NamespaceDescriptor
 */
export const NamespaceDescriptor = new NamespaceDescriptor$Type();
// @generated message type with reflection information, may provide speed optimized methods
class NamespaceState$Type extends MessageType<NamespaceState> {
    constructor() {
        super("ui.NamespaceState", [
            { no: 1, name: "namespace", kind: "message", T: () => NamespaceDescriptor },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<NamespaceState>): NamespaceState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<NamespaceState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: NamespaceState): NamespaceState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.NamespaceDescriptor namespace */ 1:
                    message.namespace = NamespaceDescriptor.internalBinaryRead(reader, reader.uint32(), options, message.namespace);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: NamespaceState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.NamespaceDescriptor namespace = 1; */
        if (message.namespace)
            NamespaceDescriptor.internalBinaryWrite(message.namespace, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.NamespaceState
 */
export const NamespaceState = new NamespaceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Service$Type extends MessageType<Service> {
    constructor() {
        super("ui.Service", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "labels", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 5, name: "dns_names", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 6, name: "egress_policy_enforced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "ingress_policy_enforced", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 8, name: "visibility_policy_status", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "creation_timestamp", kind: "message", T: () => Timestamp },
            { no: 10, name: "workloads", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Workload },
            { no: 12, name: "identity", kind: "scalar", T: 13 /*ScalarType.UINT32*/ }
        ]);
    }
    create(value?: PartialMessage<Service>): Service {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.name = "";
        message.namespace = "";
        message.labels = [];
        message.dnsNames = [];
        message.egressPolicyEnforced = false;
        message.ingressPolicyEnforced = false;
        message.visibilityPolicyStatus = "";
        message.workloads = [];
        message.identity = 0;
        if (value !== undefined)
            reflectionMergePartial<Service>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Service): Service {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string name */ 2:
                    message.name = reader.string();
                    break;
                case /* string namespace */ 3:
                    message.namespace = reader.string();
                    break;
                case /* repeated string labels */ 4:
                    message.labels.push(reader.string());
                    break;
                case /* repeated string dns_names */ 5:
                    message.dnsNames.push(reader.string());
                    break;
                case /* bool egress_policy_enforced */ 6:
                    message.egressPolicyEnforced = reader.bool();
                    break;
                case /* bool ingress_policy_enforced */ 7:
                    message.ingressPolicyEnforced = reader.bool();
                    break;
                case /* string visibility_policy_status */ 8:
                    message.visibilityPolicyStatus = reader.string();
                    break;
                case /* google.protobuf.Timestamp creation_timestamp */ 9:
                    message.creationTimestamp = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.creationTimestamp);
                    break;
                case /* repeated flow.Workload workloads */ 10:
                    message.workloads.push(Workload.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* uint32 identity */ 12:
                    message.identity = reader.uint32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Service, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string name = 2; */
        if (message.name !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.name);
        /* string namespace = 3; */
        if (message.namespace !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.namespace);
        /* repeated string labels = 4; */
        for (let i = 0; i < message.labels.length; i++)
            writer.tag(4, WireType.LengthDelimited).string(message.labels[i]);
        /* repeated string dns_names = 5; */
        for (let i = 0; i < message.dnsNames.length; i++)
            writer.tag(5, WireType.LengthDelimited).string(message.dnsNames[i]);
        /* bool egress_policy_enforced = 6; */
        if (message.egressPolicyEnforced !== false)
            writer.tag(6, WireType.Varint).bool(message.egressPolicyEnforced);
        /* bool ingress_policy_enforced = 7; */
        if (message.ingressPolicyEnforced !== false)
            writer.tag(7, WireType.Varint).bool(message.ingressPolicyEnforced);
        /* string visibility_policy_status = 8; */
        if (message.visibilityPolicyStatus !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.visibilityPolicyStatus);
        /* google.protobuf.Timestamp creation_timestamp = 9; */
        if (message.creationTimestamp)
            Timestamp.internalBinaryWrite(message.creationTimestamp, writer.tag(9, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.Workload workloads = 10; */
        for (let i = 0; i < message.workloads.length; i++)
            Workload.internalBinaryWrite(message.workloads[i], writer.tag(10, WireType.LengthDelimited).fork(), options).join();
        /* uint32 identity = 12; */
        if (message.identity !== 0)
            writer.tag(12, WireType.Varint).uint32(message.identity);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.Service
 */
export const Service = new Service$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceState$Type extends MessageType<ServiceState> {
    constructor() {
        super("ui.ServiceState", [
            { no: 1, name: "service", kind: "message", T: () => Service },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<ServiceState>): ServiceState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceState): ServiceState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.Service service */ 1:
                    message.service = Service.internalBinaryRead(reader, reader.uint32(), options, message.service);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.Service service = 1; */
        if (message.service)
            Service.internalBinaryWrite(message.service, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceState
 */
export const ServiceState = new ServiceState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceFilter$Type extends MessageType<ServiceFilter> {
    constructor() {
        super("ui.ServiceFilter", [
            { no: 1, name: "namespace", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceFilter>): ServiceFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespace = [];
        if (value !== undefined)
            reflectionMergePartial<ServiceFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceFilter): ServiceFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated string namespace */ 1:
                    message.namespace.push(reader.string());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated string namespace = 1; */
        for (let i = 0; i < message.namespace.length; i++)
            writer.tag(1, WireType.LengthDelimited).string(message.namespace[i]);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceFilter
 */
export const ServiceFilter = new ServiceFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLink$Type extends MessageType<ServiceLink> {
    constructor() {
        super("ui.ServiceLink", [
            { no: 1, name: "id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "source_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "destination_id", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "destination_port", kind: "scalar", T: 13 /*ScalarType.UINT32*/ },
            { no: 5, name: "ip_protocol", kind: "enum", T: () => ["ui.IPProtocol", IPProtocol] },
            { no: 6, name: "verdict", kind: "enum", T: () => ["flow.Verdict", Verdict] },
            { no: 7, name: "flow_amount", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "latency", kind: "message", T: () => ServiceLink_Latency },
            { no: 9, name: "bytes_transfered", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 10, name: "auth_type", kind: "enum", T: () => ["flow.AuthType", AuthType] },
            { no: 11, name: "is_encrypted", kind: "scalar", T: 8 /*ScalarType.BOOL*/ }
        ]);
    }
    create(value?: PartialMessage<ServiceLink>): ServiceLink {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.id = "";
        message.sourceId = "";
        message.destinationId = "";
        message.destinationPort = 0;
        message.ipProtocol = 0;
        message.verdict = 0;
        message.flowAmount = 0;
        message.bytesTransfered = 0;
        message.authType = 0;
        message.isEncrypted = false;
        if (value !== undefined)
            reflectionMergePartial<ServiceLink>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLink): ServiceLink {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string id */ 1:
                    message.id = reader.string();
                    break;
                case /* string source_id */ 2:
                    message.sourceId = reader.string();
                    break;
                case /* string destination_id */ 3:
                    message.destinationId = reader.string();
                    break;
                case /* uint32 destination_port */ 4:
                    message.destinationPort = reader.uint32();
                    break;
                case /* ui.IPProtocol ip_protocol */ 5:
                    message.ipProtocol = reader.int32();
                    break;
                case /* flow.Verdict verdict */ 6:
                    message.verdict = reader.int32();
                    break;
                case /* uint64 flow_amount */ 7:
                    message.flowAmount = reader.uint64().toNumber();
                    break;
                case /* ui.ServiceLink.Latency latency */ 8:
                    message.latency = ServiceLink_Latency.internalBinaryRead(reader, reader.uint32(), options, message.latency);
                    break;
                case /* uint64 bytes_transfered */ 9:
                    message.bytesTransfered = reader.uint64().toNumber();
                    break;
                case /* flow.AuthType auth_type */ 10:
                    message.authType = reader.int32();
                    break;
                case /* bool is_encrypted */ 11:
                    message.isEncrypted = reader.bool();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLink, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string id = 1; */
        if (message.id !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.id);
        /* string source_id = 2; */
        if (message.sourceId !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.sourceId);
        /* string destination_id = 3; */
        if (message.destinationId !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.destinationId);
        /* uint32 destination_port = 4; */
        if (message.destinationPort !== 0)
            writer.tag(4, WireType.Varint).uint32(message.destinationPort);
        /* ui.IPProtocol ip_protocol = 5; */
        if (message.ipProtocol !== 0)
            writer.tag(5, WireType.Varint).int32(message.ipProtocol);
        /* flow.Verdict verdict = 6; */
        if (message.verdict !== 0)
            writer.tag(6, WireType.Varint).int32(message.verdict);
        /* uint64 flow_amount = 7; */
        if (message.flowAmount !== 0)
            writer.tag(7, WireType.Varint).uint64(message.flowAmount);
        /* ui.ServiceLink.Latency latency = 8; */
        if (message.latency)
            ServiceLink_Latency.internalBinaryWrite(message.latency, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* uint64 bytes_transfered = 9; */
        if (message.bytesTransfered !== 0)
            writer.tag(9, WireType.Varint).uint64(message.bytesTransfered);
        /* flow.AuthType auth_type = 10; */
        if (message.authType !== 0)
            writer.tag(10, WireType.Varint).int32(message.authType);
        /* bool is_encrypted = 11; */
        if (message.isEncrypted !== false)
            writer.tag(11, WireType.Varint).bool(message.isEncrypted);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLink
 */
export const ServiceLink = new ServiceLink$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLink_Latency$Type extends MessageType<ServiceLink_Latency> {
    constructor() {
        super("ui.ServiceLink.Latency", [
            { no: 1, name: "min", kind: "message", T: () => Duration },
            { no: 2, name: "max", kind: "message", T: () => Duration },
            { no: 3, name: "avg", kind: "message", T: () => Duration }
        ]);
    }
    create(value?: PartialMessage<ServiceLink_Latency>): ServiceLink_Latency {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ServiceLink_Latency>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLink_Latency): ServiceLink_Latency {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.Duration min */ 1:
                    message.min = Duration.internalBinaryRead(reader, reader.uint32(), options, message.min);
                    break;
                case /* google.protobuf.Duration max */ 2:
                    message.max = Duration.internalBinaryRead(reader, reader.uint32(), options, message.max);
                    break;
                case /* google.protobuf.Duration avg */ 3:
                    message.avg = Duration.internalBinaryRead(reader, reader.uint32(), options, message.avg);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLink_Latency, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.Duration min = 1; */
        if (message.min)
            Duration.internalBinaryWrite(message.min, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration max = 2; */
        if (message.max)
            Duration.internalBinaryWrite(message.max, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Duration avg = 3; */
        if (message.avg)
            Duration.internalBinaryWrite(message.avg, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLink.Latency
 */
export const ServiceLink_Latency = new ServiceLink_Latency$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLinkState$Type extends MessageType<ServiceLinkState> {
    constructor() {
        super("ui.ServiceLinkState", [
            { no: 1, name: "service_link", kind: "message", T: () => ServiceLink },
            { no: 2, name: "type", kind: "enum", T: () => ["ui.StateChange", StateChange] }
        ]);
    }
    create(value?: PartialMessage<ServiceLinkState>): ServiceLinkState {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.type = 0;
        if (value !== undefined)
            reflectionMergePartial<ServiceLinkState>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLinkState): ServiceLinkState {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.ServiceLink service_link */ 1:
                    message.serviceLink = ServiceLink.internalBinaryRead(reader, reader.uint32(), options, message.serviceLink);
                    break;
                case /* ui.StateChange type */ 2:
                    message.type = reader.int32();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLinkState, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.ServiceLink service_link = 1; */
        if (message.serviceLink)
            ServiceLink.internalBinaryWrite(message.serviceLink, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.StateChange type = 2; */
        if (message.type !== 0)
            writer.tag(2, WireType.Varint).int32(message.type);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLinkState
 */
export const ServiceLinkState = new ServiceLinkState$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServiceLinkFilter$Type extends MessageType<ServiceLinkFilter> {
    constructor() {
        super("ui.ServiceLinkFilter", [
            { no: 1, name: "source", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceFilter },
            { no: 2, name: "destination", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => ServiceFilter },
            { no: 3, name: "destination_port", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "verdict", kind: "enum", repeat: 1 /*RepeatType.PACKED*/, T: () => ["flow.Verdict", Verdict] }
        ]);
    }
    create(value?: PartialMessage<ServiceLinkFilter>): ServiceLinkFilter {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.source = [];
        message.destination = [];
        message.destinationPort = [];
        message.verdict = [];
        if (value !== undefined)
            reflectionMergePartial<ServiceLinkFilter>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServiceLinkFilter): ServiceLinkFilter {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.ServiceFilter source */ 1:
                    message.source.push(ServiceFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated ui.ServiceFilter destination */ 2:
                    message.destination.push(ServiceFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated string destination_port */ 3:
                    message.destinationPort.push(reader.string());
                    break;
                case /* repeated flow.Verdict verdict */ 4:
                    if (wireType === WireType.LengthDelimited)
                        for (let e = reader.int32() + reader.pos; reader.pos < e;)
                            message.verdict.push(reader.int32());
                    else
                        message.verdict.push(reader.int32());
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServiceLinkFilter, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.ServiceFilter source = 1; */
        for (let i = 0; i < message.source.length; i++)
            ServiceFilter.internalBinaryWrite(message.source[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* repeated ui.ServiceFilter destination = 2; */
        for (let i = 0; i < message.destination.length; i++)
            ServiceFilter.internalBinaryWrite(message.destination[i], writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* repeated string destination_port = 3; */
        for (let i = 0; i < message.destinationPort.length; i++)
            writer.tag(3, WireType.LengthDelimited).string(message.destinationPort[i]);
        /* repeated flow.Verdict verdict = 4; */
        if (message.verdict.length) {
            writer.tag(4, WireType.LengthDelimited).fork();
            for (let i = 0; i < message.verdict.length; i++)
                writer.int32(message.verdict[i]);
            writer.join();
        }
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.ServiceLinkFilter
 */
export const ServiceLinkFilter = new ServiceLinkFilter$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamRequest$Type extends MessageType<GetControlStreamRequest> {
    constructor() {
        super("ui.GetControlStreamRequest", []);
    }
    create(value?: PartialMessage<GetControlStreamRequest>): GetControlStreamRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamRequest): GetControlStreamRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetControlStreamRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamRequest
 */
export const GetControlStreamRequest = new GetControlStreamRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamResponse$Type extends MessageType<GetControlStreamResponse> {
    constructor() {
        super("ui.GetControlStreamResponse", [
            { no: 1, name: "namespaces", kind: "message", oneof: "event", T: () => GetControlStreamResponse_NamespaceStates },
            { no: 2, name: "notification", kind: "message", oneof: "event", T: () => Notification }
        ]);
    }
    create(value?: PartialMessage<GetControlStreamResponse>): GetControlStreamResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.event = { oneofKind: undefined };
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamResponse): GetControlStreamResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* ui.GetControlStreamResponse.NamespaceStates namespaces */ 1:
                    message.event = {
                        oneofKind: "namespaces",
                        namespaces: GetControlStreamResponse_NamespaceStates.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).namespaces)
                    };
                    break;
                case /* ui.Notification notification */ 2:
                    message.event = {
                        oneofKind: "notification",
                        notification: Notification.internalBinaryRead(reader, reader.uint32(), options, (message.event as any).notification)
                    };
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetControlStreamResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* ui.GetControlStreamResponse.NamespaceStates namespaces = 1; */
        if (message.event.oneofKind === "namespaces")
            GetControlStreamResponse_NamespaceStates.internalBinaryWrite(message.event.namespaces, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* ui.Notification notification = 2; */
        if (message.event.oneofKind === "notification")
            Notification.internalBinaryWrite(message.event.notification, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamResponse
 */
export const GetControlStreamResponse = new GetControlStreamResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetControlStreamResponse_NamespaceStates$Type extends MessageType<GetControlStreamResponse_NamespaceStates> {
    constructor() {
        super("ui.GetControlStreamResponse.NamespaceStates", [
            { no: 1, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => NamespaceState }
        ]);
    }
    create(value?: PartialMessage<GetControlStreamResponse_NamespaceStates>): GetControlStreamResponse_NamespaceStates {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespaces = [];
        if (value !== undefined)
            reflectionMergePartial<GetControlStreamResponse_NamespaceStates>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetControlStreamResponse_NamespaceStates): GetControlStreamResponse_NamespaceStates {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated ui.NamespaceState namespaces */ 1:
                    message.namespaces.push(NamespaceState.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetControlStreamResponse_NamespaceStates, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated ui.NamespaceState namespaces = 1; */
        for (let i = 0; i < message.namespaces.length; i++)
            NamespaceState.internalBinaryWrite(message.namespaces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message ui.GetControlStreamResponse.NamespaceStates
 */
export const GetControlStreamResponse_NamespaceStates = new GetControlStreamResponse_NamespaceStates$Type();
/**
 * @generated ServiceType for protobuf service ui.UI
 */
export const UI = new ServiceType("ui.UI", [
    { name: "GetEvents", serverStreaming: true, options: {}, I: GetEventsRequest, O: GetEventsResponse },
    { name: "GetStatus", options: {}, I: GetStatusRequest, O: GetStatusResponse },
    { name: "GetControlStream", serverStreaming: true, options: {}, I: GetControlStreamRequest, O: GetControlStreamResponse }
]);
