/* eslint-disable */
// @generated by protobuf-ts 2.9.4 with parameter add_pb_suffix,eslint_disable,ts_nocheck,generate_dependencies,long_type_number
// @generated from protobuf file "observer/observer.proto" (package "observer", syntax proto3)
// tslint:disable
// @ts-nocheck
//
// SPDX-License-Identifier: Apache-2.0
// Copyright Authors of Hubble
//
import { ServiceType } from "@protobuf-ts/runtime-rpc";
import { WireType } from "@protobuf-ts/runtime";
import type { BinaryWriteOptions } from "@protobuf-ts/runtime";
import type { IBinaryWriter } from "@protobuf-ts/runtime";
import { UnknownFieldHandler } from "@protobuf-ts/runtime";
import type { BinaryReadOptions } from "@protobuf-ts/runtime";
import type { IBinaryReader } from "@protobuf-ts/runtime";
import type { PartialMessage } from "@protobuf-ts/runtime";
import { reflectionMergePartial } from "@protobuf-ts/runtime";
import { MessageType } from "@protobuf-ts/runtime";
import { NodeState } from "../relay/relay_pb";
import { DebugEvent } from "../flow/flow_pb";
import { AgentEvent } from "../flow/flow_pb";
import { LostEvent } from "../flow/flow_pb";
import { NodeStatusEvent } from "../relay/relay_pb";
import { Flow } from "../flow/flow_pb";
import { FieldMask } from "../google/protobuf/field_mask_pb";
import { Any } from "../google/protobuf/any_pb";
import { Timestamp } from "../google/protobuf/timestamp_pb";
import { FlowFilter } from "../flow/flow_pb";
import { UInt32Value } from "../google/protobuf/wrappers_pb";
/**
 * @generated from protobuf message observer.ServerStatusRequest
 */
export interface ServerStatusRequest {
}
/**
 * @generated from protobuf message observer.ServerStatusResponse
 */
export interface ServerStatusResponse {
    /**
     * number of currently captured flows
     * In a multi-node context, this is the cumulative count of all captured
     * flows.
     *
     * @generated from protobuf field: uint64 num_flows = 1;
     */
    numFlows: number;
    /**
     * maximum capacity of the ring buffer
     * In a multi-node context, this is the aggregation of all ring buffers
     * capacities.
     *
     * @generated from protobuf field: uint64 max_flows = 2;
     */
    maxFlows: number;
    /**
     * total amount of flows observed since the observer was started
     * In a multi-node context, this is the aggregation of all flows that have
     * been seen.
     *
     * @generated from protobuf field: uint64 seen_flows = 3;
     */
    seenFlows: number;
    /**
     * uptime of this observer instance in nanoseconds
     * In a multi-node context, this field corresponds to the uptime of the
     * longest living instance.
     *
     * @generated from protobuf field: uint64 uptime_ns = 4;
     */
    uptimeNs: number;
    /**
     * number of nodes for which a connection is established
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_connected_nodes = 5;
     */
    numConnectedNodes?: UInt32Value;
    /**
     * number of nodes for which a connection cannot be established
     *
     * @generated from protobuf field: google.protobuf.UInt32Value num_unavailable_nodes = 6;
     */
    numUnavailableNodes?: UInt32Value;
    /**
     * list of nodes that are unavailable
     * This list may not be exhaustive.
     *
     * @generated from protobuf field: repeated string unavailable_nodes = 7;
     */
    unavailableNodes: string[];
    /**
     * Version is the version of Cilium/Hubble.
     *
     * @generated from protobuf field: string version = 8;
     */
    version: string;
    /**
     * Approximate rate of flows seen by Hubble per second over the last minute.
     * In a multi-node context, this is the sum of all flows rates.
     *
     * @generated from protobuf field: double flows_rate = 9;
     */
    flowsRate: number;
}
/**
 * @generated from protobuf message observer.GetFlowsRequest
 */
export interface GetFlowsRequest {
    /**
     * Number of flows that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` flows, unless `first` is
     * true, then it will return the earliest `number` flows.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` flows or the
     * last `number` of flows. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream flows after
     * printing the last N flows.
     *
     * @generated from protobuf field: bool follow = 3;
     */
    follow: boolean;
    /**
     * blacklist defines a list of filters which have to match for a flow to be
     * excluded from the result.
     * If multiple blacklist filters are specified, only one of them has to
     * match for a flow to be excluded.
     *
     * @generated from protobuf field: repeated flow.FlowFilter blacklist = 5;
     */
    blacklist: FlowFilter[];
    /**
     * whitelist defines a list of filters which have to match for a flow to be
     * included in the result.
     * If multiple whitelist filters are specified, only one of them has to
     * match for a flow to be included.
     * The whitelist and blacklist can both be specified. In such cases, the
     * set of the returned flows is the set difference `whitelist - blacklist`.
     * In other words, the result will contain all flows matched by the
     * whitelist that are not also simultaneously matched by the blacklist.
     *
     * @generated from protobuf field: repeated flow.FlowFilter whitelist = 6;
     */
    whitelist: FlowFilter[];
    /**
     * Since this time for returned flows. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned flows. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
    /**
     * @generated from protobuf field: observer.GetFlowsRequest.Experimental experimental = 999;
     */
    experimental?: GetFlowsRequest_Experimental;
    /**
     * extensions can be used to add arbitrary additional metadata to GetFlowsRequest.
     * This can be used to extend functionality for other Hubble compatible
     * APIs, or experiment with new functionality without needing to change the public API.
     *
     * @generated from protobuf field: google.protobuf.Any extensions = 150000;
     */
    extensions?: Any;
}
/**
 * Experimental contains fields that are not stable yet. Support for
 * experimental features is always optional and subject to change.
 *
 * @generated from protobuf message observer.GetFlowsRequest.Experimental
 */
export interface GetFlowsRequest_Experimental {
    /**
     * FieldMask allows clients to limit flow's fields that will be returned.
     * For example, {paths: ["source.id", "destination.id"]} will return flows
     * with only these two fields set.
     *
     * @generated from protobuf field: google.protobuf.FieldMask field_mask = 1;
     */
    fieldMask?: FieldMask;
}
/**
 * GetFlowsResponse contains either a flow or a protocol message.
 *
 * @generated from protobuf message observer.GetFlowsResponse
 */
export interface GetFlowsResponse {
    /**
     * @generated from protobuf oneof: response_types
     */
    responseTypes: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 1;
         */
        flow: Flow;
    } | {
        oneofKind: "nodeStatus";
        /**
         * node_status informs clients about the state of the nodes
         * participating in this particular GetFlows request.
         *
         * @generated from protobuf field: relay.NodeStatusEvent node_status = 2;
         */
        nodeStatus: NodeStatusEvent;
    } | {
        oneofKind: "lostEvents";
        /**
         * lost_events informs clients about events which got dropped due to
         * a Hubble component being unavailable
         *
         * @generated from protobuf field: flow.LostEvent lost_events = 3;
         */
        lostEvents: LostEvent;
    } | {
        oneofKind: undefined;
    };
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    nodeName: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetAgentEventsRequest
 */
export interface GetAgentEventsRequest {
    /**
     * Number of flows that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` events, unless `first` is
     * true, then it will return the earliest `number` events.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` events or the
     * last `number` of events. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream agent events after
     * printing the last N agent events.
     *
     * @generated from protobuf field: bool follow = 2;
     */
    follow: boolean;
    // TODO: do we want to be able to specify blocklist/allowlist (previously
    // known as blacklist/whitelist)?

    /**
     * Since this time for returned agent events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned agent events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
}
/**
 * GetAgentEventsResponse contains an event received from the Cilium agent.
 *
 * @generated from protobuf message observer.GetAgentEventsResponse
 */
export interface GetAgentEventsResponse {
    /**
     * @generated from protobuf field: flow.AgentEvent agent_event = 1;
     */
    agentEvent?: AgentEvent;
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    nodeName: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetDebugEventsRequest
 */
export interface GetDebugEventsRequest {
    /**
     * Number of events that should be returned. Incompatible with `since/until`.
     * Defaults to the most recent (last) `number` events, unless `first` is
     * true, then it will return the earliest `number` events.
     *
     * @generated from protobuf field: uint64 number = 1;
     */
    number: number;
    /**
     * first specifies if we should look at the first `number` events or the
     * last `number` of events. Incompatible with `follow`.
     *
     * @generated from protobuf field: bool first = 9;
     */
    first: boolean;
    /**
     * follow sets when the server should continue to stream debug events after
     * printing the last N debug events.
     *
     * @generated from protobuf field: bool follow = 2;
     */
    follow: boolean;
    // TODO: do we want to be able to specify blocklist/allowlist (previously
    // known as blacklist/whitelist)?

    /**
     * Since this time for returned debug events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp since = 7;
     */
    since?: Timestamp;
    /**
     * Until this time for returned debug events. Incompatible with `number`.
     *
     * @generated from protobuf field: google.protobuf.Timestamp until = 8;
     */
    until?: Timestamp;
}
/**
 * GetDebugEventsResponse contains a Cilium datapath debug events.
 *
 * @generated from protobuf message observer.GetDebugEventsResponse
 */
export interface GetDebugEventsResponse {
    /**
     * @generated from protobuf field: flow.DebugEvent debug_event = 1;
     */
    debugEvent?: DebugEvent;
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    nodeName: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
/**
 * @generated from protobuf message observer.GetNodesRequest
 */
export interface GetNodesRequest {
}
/**
 * GetNodesResponse contains the list of nodes.
 *
 * @generated from protobuf message observer.GetNodesResponse
 */
export interface GetNodesResponse {
    /**
     * Nodes is an exhaustive list of nodes.
     *
     * @generated from protobuf field: repeated observer.Node nodes = 1;
     */
    nodes: Node[];
}
/**
 * Node represents a cluster node.
 *
 * @generated from protobuf message observer.Node
 */
export interface Node {
    /**
     * Name is the name of the node.
     *
     * @generated from protobuf field: string name = 1;
     */
    name: string;
    /**
     * Version is the version of Cilium/Hubble as reported by the node.
     *
     * @generated from protobuf field: string version = 2;
     */
    version: string;
    /**
     * Address is the network address of the API endpoint.
     *
     * @generated from protobuf field: string address = 3;
     */
    address: string;
    /**
     * State represents the known state of the node.
     *
     * @generated from protobuf field: relay.NodeState state = 4;
     */
    state: NodeState;
    /**
     * TLS reports TLS related information.
     *
     * @generated from protobuf field: observer.TLS tls = 5;
     */
    tls?: TLS;
    /**
     * UptimeNS is the uptime of this instance in nanoseconds
     *
     * @generated from protobuf field: uint64 uptime_ns = 6;
     */
    uptimeNs: number;
    /**
     * number of currently captured flows
     *
     * @generated from protobuf field: uint64 num_flows = 7;
     */
    numFlows: number;
    /**
     * maximum capacity of the ring buffer
     *
     * @generated from protobuf field: uint64 max_flows = 8;
     */
    maxFlows: number;
    /**
     * total amount of flows observed since the observer was started
     *
     * @generated from protobuf field: uint64 seen_flows = 9;
     */
    seenFlows: number;
}
/**
 * TLS represents TLS information.
 *
 * @generated from protobuf message observer.TLS
 */
export interface TLS {
    /**
     * Enabled reports whether TLS is enabled or not.
     *
     * @generated from protobuf field: bool enabled = 1;
     */
    enabled: boolean;
    /**
     * ServerName is the TLS server name that can be used as part of the TLS
     * cert validation process.
     *
     * @generated from protobuf field: string server_name = 2;
     */
    serverName: string;
}
/**
 * @generated from protobuf message observer.GetNamespacesRequest
 */
export interface GetNamespacesRequest {
}
/**
 * GetNamespacesResponse contains the list of namespaces.
 *
 * @generated from protobuf message observer.GetNamespacesResponse
 */
export interface GetNamespacesResponse {
    /**
     * Namespaces is a list of namespaces with flows
     *
     * @generated from protobuf field: repeated observer.Namespace namespaces = 1;
     */
    namespaces: Namespace[];
}
/**
 * @generated from protobuf message observer.Namespace
 */
export interface Namespace {
    /**
     * @generated from protobuf field: string cluster = 1;
     */
    cluster: string;
    /**
     * @generated from protobuf field: string namespace = 2;
     */
    namespace: string;
}
/**
 * ExportEvent contains an event to be exported. Not to be used outside of the
 * exporter feature.
 *
 * @generated from protobuf message observer.ExportEvent
 */
export interface ExportEvent {
    /**
     * @generated from protobuf oneof: response_types
     */
    responseTypes: {
        oneofKind: "flow";
        /**
         * @generated from protobuf field: flow.Flow flow = 1;
         */
        flow: Flow;
    } | {
        oneofKind: "nodeStatus";
        /**
         * node_status informs clients about the state of the nodes
         * participating in this particular GetFlows request.
         *
         * @generated from protobuf field: relay.NodeStatusEvent node_status = 2;
         */
        nodeStatus: NodeStatusEvent;
    } | {
        oneofKind: "lostEvents";
        /**
         * lost_events informs clients about events which got dropped due to
         * a Hubble component being unavailable
         *
         * @generated from protobuf field: flow.LostEvent lost_events = 3;
         */
        lostEvents: LostEvent;
    } | {
        oneofKind: "agentEvent";
        /**
         * agent_event informs clients about an event received from the Cilium
         * agent.
         *
         * @generated from protobuf field: flow.AgentEvent agent_event = 4;
         */
        agentEvent: AgentEvent;
    } | {
        oneofKind: "debugEvent";
        /**
         * debug_event contains Cilium datapath debug events
         *
         * @generated from protobuf field: flow.DebugEvent debug_event = 5;
         */
        debugEvent: DebugEvent;
    } | {
        oneofKind: undefined;
    };
    /**
     * Name of the node where this event was observed.
     *
     * @generated from protobuf field: string node_name = 1000;
     */
    nodeName: string;
    /**
     * Timestamp at which this event was observed.
     *
     * @generated from protobuf field: google.protobuf.Timestamp time = 1001;
     */
    time?: Timestamp;
}
// @generated message type with reflection information, may provide speed optimized methods
class ServerStatusRequest$Type extends MessageType<ServerStatusRequest> {
    constructor() {
        super("observer.ServerStatusRequest", []);
    }
    create(value?: PartialMessage<ServerStatusRequest>): ServerStatusRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<ServerStatusRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServerStatusRequest): ServerStatusRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: ServerStatusRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ServerStatusRequest
 */
export const ServerStatusRequest = new ServerStatusRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ServerStatusResponse$Type extends MessageType<ServerStatusResponse> {
    constructor() {
        super("observer.ServerStatusResponse", [
            { no: 1, name: "num_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 2, name: "max_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 3, name: "seen_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 4, name: "uptime_ns", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 5, name: "num_connected_nodes", kind: "message", T: () => UInt32Value },
            { no: 6, name: "num_unavailable_nodes", kind: "message", T: () => UInt32Value },
            { no: 7, name: "unavailable_nodes", kind: "scalar", repeat: 2 /*RepeatType.UNPACKED*/, T: 9 /*ScalarType.STRING*/ },
            { no: 8, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 9, name: "flows_rate", kind: "scalar", T: 1 /*ScalarType.DOUBLE*/ }
        ]);
    }
    create(value?: PartialMessage<ServerStatusResponse>): ServerStatusResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.numFlows = 0;
        message.maxFlows = 0;
        message.seenFlows = 0;
        message.uptimeNs = 0;
        message.unavailableNodes = [];
        message.version = "";
        message.flowsRate = 0;
        if (value !== undefined)
            reflectionMergePartial<ServerStatusResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ServerStatusResponse): ServerStatusResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 num_flows */ 1:
                    message.numFlows = reader.uint64().toNumber();
                    break;
                case /* uint64 max_flows */ 2:
                    message.maxFlows = reader.uint64().toNumber();
                    break;
                case /* uint64 seen_flows */ 3:
                    message.seenFlows = reader.uint64().toNumber();
                    break;
                case /* uint64 uptime_ns */ 4:
                    message.uptimeNs = reader.uint64().toNumber();
                    break;
                case /* google.protobuf.UInt32Value num_connected_nodes */ 5:
                    message.numConnectedNodes = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.numConnectedNodes);
                    break;
                case /* google.protobuf.UInt32Value num_unavailable_nodes */ 6:
                    message.numUnavailableNodes = UInt32Value.internalBinaryRead(reader, reader.uint32(), options, message.numUnavailableNodes);
                    break;
                case /* repeated string unavailable_nodes */ 7:
                    message.unavailableNodes.push(reader.string());
                    break;
                case /* string version */ 8:
                    message.version = reader.string();
                    break;
                case /* double flows_rate */ 9:
                    message.flowsRate = reader.double();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ServerStatusResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 num_flows = 1; */
        if (message.numFlows !== 0)
            writer.tag(1, WireType.Varint).uint64(message.numFlows);
        /* uint64 max_flows = 2; */
        if (message.maxFlows !== 0)
            writer.tag(2, WireType.Varint).uint64(message.maxFlows);
        /* uint64 seen_flows = 3; */
        if (message.seenFlows !== 0)
            writer.tag(3, WireType.Varint).uint64(message.seenFlows);
        /* uint64 uptime_ns = 4; */
        if (message.uptimeNs !== 0)
            writer.tag(4, WireType.Varint).uint64(message.uptimeNs);
        /* google.protobuf.UInt32Value num_connected_nodes = 5; */
        if (message.numConnectedNodes)
            UInt32Value.internalBinaryWrite(message.numConnectedNodes, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.UInt32Value num_unavailable_nodes = 6; */
        if (message.numUnavailableNodes)
            UInt32Value.internalBinaryWrite(message.numUnavailableNodes, writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* repeated string unavailable_nodes = 7; */
        for (let i = 0; i < message.unavailableNodes.length; i++)
            writer.tag(7, WireType.LengthDelimited).string(message.unavailableNodes[i]);
        /* string version = 8; */
        if (message.version !== "")
            writer.tag(8, WireType.LengthDelimited).string(message.version);
        /* double flows_rate = 9; */
        if (message.flowsRate !== 0)
            writer.tag(9, WireType.Bit64).double(message.flowsRate);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ServerStatusResponse
 */
export const ServerStatusResponse = new ServerStatusResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsRequest$Type extends MessageType<GetFlowsRequest> {
    constructor() {
        super("observer.GetFlowsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 3, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 5, name: "blacklist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 6, name: "whitelist", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => FlowFilter },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp },
            { no: 999, name: "experimental", kind: "message", T: () => GetFlowsRequest_Experimental },
            { no: 150000, name: "extensions", kind: "message", T: () => Any }
        ]);
    }
    create(value?: PartialMessage<GetFlowsRequest>): GetFlowsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        message.blacklist = [];
        message.whitelist = [];
        if (value !== undefined)
            reflectionMergePartial<GetFlowsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsRequest): GetFlowsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 3:
                    message.follow = reader.bool();
                    break;
                case /* repeated flow.FlowFilter blacklist */ 5:
                    message.blacklist.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* repeated flow.FlowFilter whitelist */ 6:
                    message.whitelist.push(FlowFilter.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                case /* observer.GetFlowsRequest.Experimental experimental */ 999:
                    message.experimental = GetFlowsRequest_Experimental.internalBinaryRead(reader, reader.uint32(), options, message.experimental);
                    break;
                case /* google.protobuf.Any extensions */ 150000:
                    message.extensions = Any.internalBinaryRead(reader, reader.uint32(), options, message.extensions);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 3; */
        if (message.follow !== false)
            writer.tag(3, WireType.Varint).bool(message.follow);
        /* repeated flow.FlowFilter blacklist = 5; */
        for (let i = 0; i < message.blacklist.length; i++)
            FlowFilter.internalBinaryWrite(message.blacklist[i], writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* repeated flow.FlowFilter whitelist = 6; */
        for (let i = 0; i < message.whitelist.length; i++)
            FlowFilter.internalBinaryWrite(message.whitelist[i], writer.tag(6, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        /* observer.GetFlowsRequest.Experimental experimental = 999; */
        if (message.experimental)
            GetFlowsRequest_Experimental.internalBinaryWrite(message.experimental, writer.tag(999, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Any extensions = 150000; */
        if (message.extensions)
            Any.internalBinaryWrite(message.extensions, writer.tag(150000, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsRequest
 */
export const GetFlowsRequest = new GetFlowsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsRequest_Experimental$Type extends MessageType<GetFlowsRequest_Experimental> {
    constructor() {
        super("observer.GetFlowsRequest.Experimental", [
            { no: 1, name: "field_mask", kind: "message", T: () => FieldMask }
        ]);
    }
    create(value?: PartialMessage<GetFlowsRequest_Experimental>): GetFlowsRequest_Experimental {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetFlowsRequest_Experimental>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsRequest_Experimental): GetFlowsRequest_Experimental {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* google.protobuf.FieldMask field_mask */ 1:
                    message.fieldMask = FieldMask.internalBinaryRead(reader, reader.uint32(), options, message.fieldMask);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsRequest_Experimental, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* google.protobuf.FieldMask field_mask = 1; */
        if (message.fieldMask)
            FieldMask.internalBinaryWrite(message.fieldMask, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsRequest.Experimental
 */
export const GetFlowsRequest_Experimental = new GetFlowsRequest_Experimental$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetFlowsResponse$Type extends MessageType<GetFlowsResponse> {
    constructor() {
        super("observer.GetFlowsResponse", [
            { no: 1, name: "flow", kind: "message", oneof: "responseTypes", T: () => Flow },
            { no: 2, name: "node_status", kind: "message", oneof: "responseTypes", T: () => NodeStatusEvent },
            { no: 3, name: "lost_events", kind: "message", oneof: "responseTypes", T: () => LostEvent },
            { no: 1000, name: "node_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetFlowsResponse>): GetFlowsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.responseTypes = { oneofKind: undefined };
        message.nodeName = "";
        if (value !== undefined)
            reflectionMergePartial<GetFlowsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetFlowsResponse): GetFlowsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 1:
                    message.responseTypes = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).flow)
                    };
                    break;
                case /* relay.NodeStatusEvent node_status */ 2:
                    message.responseTypes = {
                        oneofKind: "nodeStatus",
                        nodeStatus: NodeStatusEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).nodeStatus)
                    };
                    break;
                case /* flow.LostEvent lost_events */ 3:
                    message.responseTypes = {
                        oneofKind: "lostEvents",
                        lostEvents: LostEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).lostEvents)
                    };
                    break;
                case /* string node_name */ 1000:
                    message.nodeName = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetFlowsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 1; */
        if (message.responseTypes.oneofKind === "flow")
            Flow.internalBinaryWrite(message.responseTypes.flow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* relay.NodeStatusEvent node_status = 2; */
        if (message.responseTypes.oneofKind === "nodeStatus")
            NodeStatusEvent.internalBinaryWrite(message.responseTypes.nodeStatus, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* flow.LostEvent lost_events = 3; */
        if (message.responseTypes.oneofKind === "lostEvents")
            LostEvent.internalBinaryWrite(message.responseTypes.lostEvents, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.nodeName !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.nodeName);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetFlowsResponse
 */
export const GetFlowsResponse = new GetFlowsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentEventsRequest$Type extends MessageType<GetAgentEventsRequest> {
    constructor() {
        super("observer.GetAgentEventsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAgentEventsRequest>): GetAgentEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        if (value !== undefined)
            reflectionMergePartial<GetAgentEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentEventsRequest): GetAgentEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 2:
                    message.follow = reader.bool();
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 2; */
        if (message.follow !== false)
            writer.tag(2, WireType.Varint).bool(message.follow);
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetAgentEventsRequest
 */
export const GetAgentEventsRequest = new GetAgentEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetAgentEventsResponse$Type extends MessageType<GetAgentEventsResponse> {
    constructor() {
        super("observer.GetAgentEventsResponse", [
            { no: 1, name: "agent_event", kind: "message", T: () => AgentEvent },
            { no: 1000, name: "node_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetAgentEventsResponse>): GetAgentEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nodeName = "";
        if (value !== undefined)
            reflectionMergePartial<GetAgentEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetAgentEventsResponse): GetAgentEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.AgentEvent agent_event */ 1:
                    message.agentEvent = AgentEvent.internalBinaryRead(reader, reader.uint32(), options, message.agentEvent);
                    break;
                case /* string node_name */ 1000:
                    message.nodeName = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetAgentEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.AgentEvent agent_event = 1; */
        if (message.agentEvent)
            AgentEvent.internalBinaryWrite(message.agentEvent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.nodeName !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.nodeName);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetAgentEventsResponse
 */
export const GetAgentEventsResponse = new GetAgentEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDebugEventsRequest$Type extends MessageType<GetDebugEventsRequest> {
    constructor() {
        super("observer.GetDebugEventsRequest", [
            { no: 1, name: "number", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "first", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "follow", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 7, name: "since", kind: "message", T: () => Timestamp },
            { no: 8, name: "until", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetDebugEventsRequest>): GetDebugEventsRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.number = 0;
        message.first = false;
        message.follow = false;
        if (value !== undefined)
            reflectionMergePartial<GetDebugEventsRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDebugEventsRequest): GetDebugEventsRequest {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* uint64 number */ 1:
                    message.number = reader.uint64().toNumber();
                    break;
                case /* bool first */ 9:
                    message.first = reader.bool();
                    break;
                case /* bool follow */ 2:
                    message.follow = reader.bool();
                    break;
                case /* google.protobuf.Timestamp since */ 7:
                    message.since = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.since);
                    break;
                case /* google.protobuf.Timestamp until */ 8:
                    message.until = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.until);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDebugEventsRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* uint64 number = 1; */
        if (message.number !== 0)
            writer.tag(1, WireType.Varint).uint64(message.number);
        /* bool first = 9; */
        if (message.first !== false)
            writer.tag(9, WireType.Varint).bool(message.first);
        /* bool follow = 2; */
        if (message.follow !== false)
            writer.tag(2, WireType.Varint).bool(message.follow);
        /* google.protobuf.Timestamp since = 7; */
        if (message.since)
            Timestamp.internalBinaryWrite(message.since, writer.tag(7, WireType.LengthDelimited).fork(), options).join();
        /* google.protobuf.Timestamp until = 8; */
        if (message.until)
            Timestamp.internalBinaryWrite(message.until, writer.tag(8, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetDebugEventsRequest
 */
export const GetDebugEventsRequest = new GetDebugEventsRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetDebugEventsResponse$Type extends MessageType<GetDebugEventsResponse> {
    constructor() {
        super("observer.GetDebugEventsResponse", [
            { no: 1, name: "debug_event", kind: "message", T: () => DebugEvent },
            { no: 1000, name: "node_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<GetDebugEventsResponse>): GetDebugEventsResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nodeName = "";
        if (value !== undefined)
            reflectionMergePartial<GetDebugEventsResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetDebugEventsResponse): GetDebugEventsResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.DebugEvent debug_event */ 1:
                    message.debugEvent = DebugEvent.internalBinaryRead(reader, reader.uint32(), options, message.debugEvent);
                    break;
                case /* string node_name */ 1000:
                    message.nodeName = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetDebugEventsResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.DebugEvent debug_event = 1; */
        if (message.debugEvent)
            DebugEvent.internalBinaryWrite(message.debugEvent, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.nodeName !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.nodeName);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetDebugEventsResponse
 */
export const GetDebugEventsResponse = new GetDebugEventsResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNodesRequest$Type extends MessageType<GetNodesRequest> {
    constructor() {
        super("observer.GetNodesRequest", []);
    }
    create(value?: PartialMessage<GetNodesRequest>): GetNodesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNodesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNodesRequest): GetNodesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNodesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNodesRequest
 */
export const GetNodesRequest = new GetNodesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNodesResponse$Type extends MessageType<GetNodesResponse> {
    constructor() {
        super("observer.GetNodesResponse", [
            { no: 1, name: "nodes", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Node }
        ]);
    }
    create(value?: PartialMessage<GetNodesResponse>): GetNodesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.nodes = [];
        if (value !== undefined)
            reflectionMergePartial<GetNodesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNodesResponse): GetNodesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated observer.Node nodes */ 1:
                    message.nodes.push(Node.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNodesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated observer.Node nodes = 1; */
        for (let i = 0; i < message.nodes.length; i++)
            Node.internalBinaryWrite(message.nodes[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNodesResponse
 */
export const GetNodesResponse = new GetNodesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Node$Type extends MessageType<Node> {
    constructor() {
        super("observer.Node", [
            { no: 1, name: "name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "version", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 3, name: "address", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 4, name: "state", kind: "enum", T: () => ["relay.NodeState", NodeState] },
            { no: 5, name: "tls", kind: "message", T: () => TLS },
            { no: 6, name: "uptime_ns", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 7, name: "num_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 8, name: "max_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ },
            { no: 9, name: "seen_flows", kind: "scalar", T: 4 /*ScalarType.UINT64*/, L: 2 /*LongType.NUMBER*/ }
        ]);
    }
    create(value?: PartialMessage<Node>): Node {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.name = "";
        message.version = "";
        message.address = "";
        message.state = 0;
        message.uptimeNs = 0;
        message.numFlows = 0;
        message.maxFlows = 0;
        message.seenFlows = 0;
        if (value !== undefined)
            reflectionMergePartial<Node>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Node): Node {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string name */ 1:
                    message.name = reader.string();
                    break;
                case /* string version */ 2:
                    message.version = reader.string();
                    break;
                case /* string address */ 3:
                    message.address = reader.string();
                    break;
                case /* relay.NodeState state */ 4:
                    message.state = reader.int32();
                    break;
                case /* observer.TLS tls */ 5:
                    message.tls = TLS.internalBinaryRead(reader, reader.uint32(), options, message.tls);
                    break;
                case /* uint64 uptime_ns */ 6:
                    message.uptimeNs = reader.uint64().toNumber();
                    break;
                case /* uint64 num_flows */ 7:
                    message.numFlows = reader.uint64().toNumber();
                    break;
                case /* uint64 max_flows */ 8:
                    message.maxFlows = reader.uint64().toNumber();
                    break;
                case /* uint64 seen_flows */ 9:
                    message.seenFlows = reader.uint64().toNumber();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Node, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string name = 1; */
        if (message.name !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.name);
        /* string version = 2; */
        if (message.version !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.version);
        /* string address = 3; */
        if (message.address !== "")
            writer.tag(3, WireType.LengthDelimited).string(message.address);
        /* relay.NodeState state = 4; */
        if (message.state !== 0)
            writer.tag(4, WireType.Varint).int32(message.state);
        /* observer.TLS tls = 5; */
        if (message.tls)
            TLS.internalBinaryWrite(message.tls, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* uint64 uptime_ns = 6; */
        if (message.uptimeNs !== 0)
            writer.tag(6, WireType.Varint).uint64(message.uptimeNs);
        /* uint64 num_flows = 7; */
        if (message.numFlows !== 0)
            writer.tag(7, WireType.Varint).uint64(message.numFlows);
        /* uint64 max_flows = 8; */
        if (message.maxFlows !== 0)
            writer.tag(8, WireType.Varint).uint64(message.maxFlows);
        /* uint64 seen_flows = 9; */
        if (message.seenFlows !== 0)
            writer.tag(9, WireType.Varint).uint64(message.seenFlows);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.Node
 */
export const Node = new Node$Type();
// @generated message type with reflection information, may provide speed optimized methods
class TLS$Type extends MessageType<TLS> {
    constructor() {
        super("observer.TLS", [
            { no: 1, name: "enabled", kind: "scalar", T: 8 /*ScalarType.BOOL*/ },
            { no: 2, name: "server_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<TLS>): TLS {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.enabled = false;
        message.serverName = "";
        if (value !== undefined)
            reflectionMergePartial<TLS>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: TLS): TLS {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* bool enabled */ 1:
                    message.enabled = reader.bool();
                    break;
                case /* string server_name */ 2:
                    message.serverName = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: TLS, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* bool enabled = 1; */
        if (message.enabled !== false)
            writer.tag(1, WireType.Varint).bool(message.enabled);
        /* string server_name = 2; */
        if (message.serverName !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.serverName);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.TLS
 */
export const TLS = new TLS$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNamespacesRequest$Type extends MessageType<GetNamespacesRequest> {
    constructor() {
        super("observer.GetNamespacesRequest", []);
    }
    create(value?: PartialMessage<GetNamespacesRequest>): GetNamespacesRequest {
        const message = globalThis.Object.create((this.messagePrototype!));
        if (value !== undefined)
            reflectionMergePartial<GetNamespacesRequest>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNamespacesRequest): GetNamespacesRequest {
        return target ?? this.create();
    }
    internalBinaryWrite(message: GetNamespacesRequest, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNamespacesRequest
 */
export const GetNamespacesRequest = new GetNamespacesRequest$Type();
// @generated message type with reflection information, may provide speed optimized methods
class GetNamespacesResponse$Type extends MessageType<GetNamespacesResponse> {
    constructor() {
        super("observer.GetNamespacesResponse", [
            { no: 1, name: "namespaces", kind: "message", repeat: 1 /*RepeatType.PACKED*/, T: () => Namespace }
        ]);
    }
    create(value?: PartialMessage<GetNamespacesResponse>): GetNamespacesResponse {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.namespaces = [];
        if (value !== undefined)
            reflectionMergePartial<GetNamespacesResponse>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: GetNamespacesResponse): GetNamespacesResponse {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* repeated observer.Namespace namespaces */ 1:
                    message.namespaces.push(Namespace.internalBinaryRead(reader, reader.uint32(), options));
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: GetNamespacesResponse, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* repeated observer.Namespace namespaces = 1; */
        for (let i = 0; i < message.namespaces.length; i++)
            Namespace.internalBinaryWrite(message.namespaces[i], writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.GetNamespacesResponse
 */
export const GetNamespacesResponse = new GetNamespacesResponse$Type();
// @generated message type with reflection information, may provide speed optimized methods
class Namespace$Type extends MessageType<Namespace> {
    constructor() {
        super("observer.Namespace", [
            { no: 1, name: "cluster", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 2, name: "namespace", kind: "scalar", T: 9 /*ScalarType.STRING*/ }
        ]);
    }
    create(value?: PartialMessage<Namespace>): Namespace {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.cluster = "";
        message.namespace = "";
        if (value !== undefined)
            reflectionMergePartial<Namespace>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: Namespace): Namespace {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* string cluster */ 1:
                    message.cluster = reader.string();
                    break;
                case /* string namespace */ 2:
                    message.namespace = reader.string();
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: Namespace, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* string cluster = 1; */
        if (message.cluster !== "")
            writer.tag(1, WireType.LengthDelimited).string(message.cluster);
        /* string namespace = 2; */
        if (message.namespace !== "")
            writer.tag(2, WireType.LengthDelimited).string(message.namespace);
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.Namespace
 */
export const Namespace = new Namespace$Type();
// @generated message type with reflection information, may provide speed optimized methods
class ExportEvent$Type extends MessageType<ExportEvent> {
    constructor() {
        super("observer.ExportEvent", [
            { no: 1, name: "flow", kind: "message", oneof: "responseTypes", T: () => Flow },
            { no: 2, name: "node_status", kind: "message", oneof: "responseTypes", T: () => NodeStatusEvent },
            { no: 3, name: "lost_events", kind: "message", oneof: "responseTypes", T: () => LostEvent },
            { no: 4, name: "agent_event", kind: "message", oneof: "responseTypes", T: () => AgentEvent },
            { no: 5, name: "debug_event", kind: "message", oneof: "responseTypes", T: () => DebugEvent },
            { no: 1000, name: "node_name", kind: "scalar", T: 9 /*ScalarType.STRING*/ },
            { no: 1001, name: "time", kind: "message", T: () => Timestamp }
        ]);
    }
    create(value?: PartialMessage<ExportEvent>): ExportEvent {
        const message = globalThis.Object.create((this.messagePrototype!));
        message.responseTypes = { oneofKind: undefined };
        message.nodeName = "";
        if (value !== undefined)
            reflectionMergePartial<ExportEvent>(this, message, value);
        return message;
    }
    internalBinaryRead(reader: IBinaryReader, length: number, options: BinaryReadOptions, target?: ExportEvent): ExportEvent {
        let message = target ?? this.create(), end = reader.pos + length;
        while (reader.pos < end) {
            let [fieldNo, wireType] = reader.tag();
            switch (fieldNo) {
                case /* flow.Flow flow */ 1:
                    message.responseTypes = {
                        oneofKind: "flow",
                        flow: Flow.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).flow)
                    };
                    break;
                case /* relay.NodeStatusEvent node_status */ 2:
                    message.responseTypes = {
                        oneofKind: "nodeStatus",
                        nodeStatus: NodeStatusEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).nodeStatus)
                    };
                    break;
                case /* flow.LostEvent lost_events */ 3:
                    message.responseTypes = {
                        oneofKind: "lostEvents",
                        lostEvents: LostEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).lostEvents)
                    };
                    break;
                case /* flow.AgentEvent agent_event */ 4:
                    message.responseTypes = {
                        oneofKind: "agentEvent",
                        agentEvent: AgentEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).agentEvent)
                    };
                    break;
                case /* flow.DebugEvent debug_event */ 5:
                    message.responseTypes = {
                        oneofKind: "debugEvent",
                        debugEvent: DebugEvent.internalBinaryRead(reader, reader.uint32(), options, (message.responseTypes as any).debugEvent)
                    };
                    break;
                case /* string node_name */ 1000:
                    message.nodeName = reader.string();
                    break;
                case /* google.protobuf.Timestamp time */ 1001:
                    message.time = Timestamp.internalBinaryRead(reader, reader.uint32(), options, message.time);
                    break;
                default:
                    let u = options.readUnknownField;
                    if (u === "throw")
                        throw new globalThis.Error(`Unknown field ${fieldNo} (wire type ${wireType}) for ${this.typeName}`);
                    let d = reader.skip(wireType);
                    if (u !== false)
                        (u === true ? UnknownFieldHandler.onRead : u)(this.typeName, message, fieldNo, wireType, d);
            }
        }
        return message;
    }
    internalBinaryWrite(message: ExportEvent, writer: IBinaryWriter, options: BinaryWriteOptions): IBinaryWriter {
        /* flow.Flow flow = 1; */
        if (message.responseTypes.oneofKind === "flow")
            Flow.internalBinaryWrite(message.responseTypes.flow, writer.tag(1, WireType.LengthDelimited).fork(), options).join();
        /* relay.NodeStatusEvent node_status = 2; */
        if (message.responseTypes.oneofKind === "nodeStatus")
            NodeStatusEvent.internalBinaryWrite(message.responseTypes.nodeStatus, writer.tag(2, WireType.LengthDelimited).fork(), options).join();
        /* flow.LostEvent lost_events = 3; */
        if (message.responseTypes.oneofKind === "lostEvents")
            LostEvent.internalBinaryWrite(message.responseTypes.lostEvents, writer.tag(3, WireType.LengthDelimited).fork(), options).join();
        /* flow.AgentEvent agent_event = 4; */
        if (message.responseTypes.oneofKind === "agentEvent")
            AgentEvent.internalBinaryWrite(message.responseTypes.agentEvent, writer.tag(4, WireType.LengthDelimited).fork(), options).join();
        /* flow.DebugEvent debug_event = 5; */
        if (message.responseTypes.oneofKind === "debugEvent")
            DebugEvent.internalBinaryWrite(message.responseTypes.debugEvent, writer.tag(5, WireType.LengthDelimited).fork(), options).join();
        /* string node_name = 1000; */
        if (message.nodeName !== "")
            writer.tag(1000, WireType.LengthDelimited).string(message.nodeName);
        /* google.protobuf.Timestamp time = 1001; */
        if (message.time)
            Timestamp.internalBinaryWrite(message.time, writer.tag(1001, WireType.LengthDelimited).fork(), options).join();
        let u = options.writeUnknownFields;
        if (u !== false)
            (u == true ? UnknownFieldHandler.onWrite : u)(this.typeName, message, writer);
        return writer;
    }
}
/**
 * @generated MessageType for protobuf message observer.ExportEvent
 */
export const ExportEvent = new ExportEvent$Type();
/**
 * @generated ServiceType for protobuf service observer.Observer
 */
export const Observer = new ServiceType("observer.Observer", [
    { name: "GetFlows", serverStreaming: true, options: {}, I: GetFlowsRequest, O: GetFlowsResponse },
    { name: "GetAgentEvents", serverStreaming: true, options: {}, I: GetAgentEventsRequest, O: GetAgentEventsResponse },
    { name: "GetDebugEvents", serverStreaming: true, options: {}, I: GetDebugEventsRequest, O: GetDebugEventsResponse },
    { name: "GetNodes", options: {}, I: GetNodesRequest, O: GetNodesResponse },
    { name: "GetNamespaces", options: {}, I: GetNamespacesRequest, O: GetNamespacesResponse },
    { name: "ServerStatus", options: {}, I: ServerStatusRequest, O: ServerStatusResponse }
]);
